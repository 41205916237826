import { ErrorHandler, inject, Injectable, NgZone } from '@angular/core';
import { NotificationService } from './notification.service';
import { TranslationService } from './translation.service';
import { AuthService } from './auth.service';
import { take } from 'rxjs/operators';
import { SentryService } from './sentry.service';
import { ServiceUnavailableError } from '../common/service-unavailable-error';
import { ServiceReadonlyError } from '../common/service-readonly-error';
import { ReleaseService } from './release.service';
import { NotAuthenticatedError } from '../common/not-authenticated-error';
import { NotAuthorizedError } from '../common/not-authorized-error';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // Make the injector create translation service early so that the call to get translations is finished.
  // Because we're using an injector this is NOT the same copy as the global translation service
  // noinspection JSUnusedLocalSymbols
  private translationService = inject(TranslationService);
  private sentryService = inject(SentryService);
  // The ErrorHandler is executed outside of the Angular zone (otherwise thrown errors will be re-caught)
  // We want to show the modal from within the angular zone so that normal detection cycles take place
  private zone = inject(NgZone);
  private notificationService = inject(NotificationService);
  private authService = inject(AuthService);
  private chunkFailedMessage = /Failed to fetch dynamically imported module: http.*\.js/;

  private releaseService = inject(ReleaseService);

  handleError(error: any) {
    if (error.error === 'login_required' || error instanceof NotAuthenticatedError) {
      this.zone.run(() => this.authService.login());
    } else {
      this.handleGenericError(error);
    }
  }

  handleGenericError(error: Error) {
    if (error instanceof ServiceUnavailableError) {
      this.showServiceUnavailableError(error);
    } else if (error instanceof ServiceReadonlyError) {
      this.showServiceReadonlyError(error);
    } else if (error instanceof NotAuthorizedError) {
      this.showNotAuthorizedError(error);
    } else if (this.chunkFailedMessage.test(error.message)) {
      this.releaseService.showReloadNotification();
    } else {
      this.showSentryDialog(error);
    }
  }

  private showSentryDialog(error: Error) {
    this.authService.userProfile$.pipe(take(1)).subscribe({
      next: (userProfile) => {
        this.sentryService.showErrorDialogAndLog(error, {
          email: userProfile?.email,
          name: userProfile?.name,
        });
      },
      error: () => {
        this.sentryService.showErrorDialogAndLog(error);
      },
    });
  }

  private showNotAuthorizedError(error: NotAuthorizedError) {
    this.notificationService.showErrorDialog(
      this.translationService.getTranslationById('core.error.notAuthorized'),
      'core.notifications.title.error',
      false,
    );

    this.sentryService.logError(error);
  }

  private showServiceUnavailableError(error: ServiceUnavailableError) {
    this.notificationService.showErrorDialog(
      this.translationService.getTranslationByIdAndParams('core.error.serviceUnavailable', {
        serviceName: error.serviceName,
      }),
      'core.notifications.title.error',
      false,
    );

    this.sentryService.logError(error);
  }

  private showServiceReadonlyError(error: ServiceReadonlyError) {
    this.notificationService.showInformationDialog(
      this.translationService.getTranslationByIdAndParams('core.error.serviceReadonly', {
        serviceName: error.serviceName,
      }),
      'core.notifications.title.information',
      false,
    );
  }
}

export const GlobalErrorHandlerProvider = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler,
};
